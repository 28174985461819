<template>
  <div class="b-auth__slider slider-wrapper">
    <b-carousel
      id="carousel-1"
      class="slider"
      :interval="0"
      controls
      indicators
    >
      <b-carousel-slide>
        <img
          id="slide1"
          src="/img/auth/slide1.svg"
          class="slide-img"
        />
        <div class="slide-description">Добро пожаловать в систему автоматизации торговых процессов!</div>
      </b-carousel-slide>
      <b-carousel-slide>
        <img
          id="slide1"
          src="/img/auth/slide2.svg"
          class="slide-img"
        />
        <div class="slide-description">
          Все надежно! Мы используем защищенную инфраструктуру облачного хранилища и шифруем все конфиденциальные данные
        </div>
      </b-carousel-slide>
      <b-carousel-slide>
        <img
          src="/img/auth/slide3.svg"
          alt=""
          class="slide-img"
        />
        <div class="slide-description">
          Забота о вас и эффективности бизнеса - наш приоритет, поэтому поддержка Энтерсайта всегда готова помочь в
          трудную минуту
        </div>
      </b-carousel-slide>
      <b-carousel-slide>
        <img
          id="slide4"
          src="/img/auth/slide4.svg"
          class="slide-img"
        />
        <div class="slide-description">
          Работа в системе проста и интуитивно понятна. Мы непрерывно проводим глубокую аналитику и улучшаем ваше
          взаимодействие с интерфейсом
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .slider-wrapper {
    display: flex;
    align-items: stretch;
    width: 100%;
    box-shadow: -20px 0px 36px rgba(0, 0, 0, 0.06);
  }
  .slider {
    height: 100%;
    width: 100%;

    &::v-deep {
      .carousel-inner {
        height: 100%;
      }
      .carousel-control-prev,
      .carousel-control-next {
        width: 32px;
        height: 32px;
        top: 50%;
        transform: translateY(-50%);
        background: #efefef;
        border-radius: 50%;
        opacity: 1;
        transition: background 0.2s;

        &:hover,
        &:focus {
          background: #ddd;
        }
      }
      .carousel-control-prev {
        left: 18px;
      }
      .carousel-control-next {
        right: 18px;
      }
      .carousel-control-prev-icon {
        transform: rotate(180deg);
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        background: url('/img/icons/arrow.svg') no-repeat 50% 50%;
      }
      .carousel-item {
        height: 100%;
        // display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      .carousel-caption {
        position: static;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .carousel-indicators {
        gap: 20px;
        justify-content: center;
        margin: 0 auto 50px;

        li {
          width: 10px;
          height: 10px;
          background: #b9d6ff;
          border-radius: 50%;
          padding: 0;
          border: 0;
          opacity: 1;
          transition: all 0.2s;

          &:hover {
            background: #0066ff;
          }
          &.active {
            background: #0066ff;
            transform: scale(1.4);
          }
        }
      }
    }
  }
  .slide-img {
    max-height: 440px;
    pointer-events: none;
  }
  .slide-description {
    margin: 35px auto;
    max-width: 487px;
    color: #000;
    font: normal 22px/26px 'Gilroy-Regular', sans-serif;
  }
</style>
