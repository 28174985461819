var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-auth__slider slider-wrapper"
  }, [_c('b-carousel', {
    staticClass: "slider",
    attrs: {
      "id": "carousel-1",
      "interval": 0,
      "controls": "",
      "indicators": ""
    }
  }, [_c('b-carousel-slide', [_c('img', {
    staticClass: "slide-img",
    attrs: {
      "id": "slide1",
      "src": "/img/auth/slide1.svg"
    }
  }), _c('div', {
    staticClass: "slide-description"
  }, [_vm._v("Добро пожаловать в систему автоматизации торговых процессов!")])]), _c('b-carousel-slide', [_c('img', {
    staticClass: "slide-img",
    attrs: {
      "id": "slide1",
      "src": "/img/auth/slide2.svg"
    }
  }), _c('div', {
    staticClass: "slide-description"
  }, [_vm._v(" Все надежно! Мы используем защищенную инфраструктуру облачного хранилища и шифруем все конфиденциальные данные ")])]), _c('b-carousel-slide', [_c('img', {
    staticClass: "slide-img",
    attrs: {
      "src": "/img/auth/slide3.svg",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "slide-description"
  }, [_vm._v(" Забота о вас и эффективности бизнеса - наш приоритет, поэтому поддержка Энтерсайта всегда готова помочь в трудную минуту ")])]), _c('b-carousel-slide', [_c('img', {
    staticClass: "slide-img",
    attrs: {
      "id": "slide4",
      "src": "/img/auth/slide4.svg"
    }
  }), _c('div', {
    staticClass: "slide-description"
  }, [_vm._v(" Работа в системе проста и интуитивно понятна. Мы непрерывно проводим глубокую аналитику и улучшаем ваше взаимодействие с интерфейсом ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }