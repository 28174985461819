var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-auth__wrapper"
  }, [_c('div', {
    staticClass: "b-auth__form"
  }, [_vm._m(0), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitHandler.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-4"
  }, [_c('label', [_vm._v("E-mail или контактный телефон")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.user_login,
      callback: function ($$v) {
        _vm.user_login = $$v;
      },
      expression: "user_login"
    }
  }), _vm.login_error ? _c('p', {
    staticClass: "error mt-2"
  }, [_vm._v(" Введите корректный email или телефон ")]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-4"
  }, [_c('label', [_vm._v("Пароль")]), _c('password-input', {
    attrs: {
      "required": ""
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('p', {
    staticClass: "reset-pass mb-4",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/auth/reset-password');
      }
    }
  }, [_vm._v(" Забыли пароль? ")])]), _c('b-row', [_c('b-col', {
    staticClass: "mb-4"
  }, [_c('b-button', {
    staticClass: "w-100 text-center justify-content-center",
    attrs: {
      "block": "",
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Войти ")])], 1)], 1), _vm.error_message ? _c('p', {
    staticClass: "error center"
  }, [_vm._v(" " + _vm._s(_vm.error_message_text) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "b-auth__login"
  }, [_vm._v(" Еще нет аккаунта? "), _c('router-link', {
    attrs: {
      "to": "registr"
    }
  }, [_vm._v("Зарегистрироваться")])], 1)], 1), _c('slider')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "head-text center"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('img', {
    attrs: {
      "src": require("../../../public/img/icons/newLogo.svg")
    }
  })]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("Вход в систему")])]);

}]

export { render, staticRenderFns }