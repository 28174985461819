<template>
  <div class="b-auth__wrapper">
    <div class="b-auth__form">
      <div class="head-text center">
        <div class="title">
          <img src="../../../public/img/icons/newLogo.svg" />
        </div>
        <div class="desc">Вход в систему</div>
      </div>
      <b-form @submit.prevent="submitHandler">
        <b-row>
          <b-col class="mb-4">
            <label>E-mail или контактный телефон</label>
            <b-form-input
              v-model="user_login"
              type="text"
              required
            />
            <p
              v-if="login_error"
              class="error mt-2"
            >
              Введите корректный email или телефон
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mb-4">
            <label>Пароль</label>
            <password-input
              v-model="password"
              required
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <p
            class="reset-pass mb-4"
            @click="$router.push('/auth/reset-password')"
          >
            Забыли пароль?
          </p>
        </div>
        <b-row>
          <b-col class="mb-4">
            <b-button
              block
              type="submit"
              variant="primary"
              class="w-100 text-center justify-content-center"
            >
              Войти
            </b-button>
          </b-col>
        </b-row>
        <p
          v-if="error_message"
          class="error center"
        >
          {{ error_message_text }}
        </p>
      </b-form>
      <div class="b-auth__login">
        Еще нет аккаунта?
        <router-link to="registr">Зарегистрироваться</router-link>
      </div>
    </div>
    <slider />
  </div>
</template>

<script>
  import Slider from './components/Slider.vue'
  import PasswordInput from '../shared/components/elements/PasswordInput'
  import { isEmail, isPhoneNumber } from '@utils/rules'
  import { mapActions } from 'vuex'
  import * as R from 'ramda'

  export default {
    components: {
      Slider,
      PasswordInput
    },

    data: () => ({
      user_login: '',
      password: '',
      login_error: false,
      error_message: false,
      error_message_text: 'Логин или пароль недействительны'
    }),

    methods: {
      ...mapActions({
        setUserData: 'core/setUserData'
      }),

      submitHandler() {
        const authDetails = {
          password: this.password
        }

        this.error_message = false

        if (isEmail(this.user_login)) authDetails.email = this.user_login
        if (isPhoneNumber(this.user_login)) authDetails.phone = this.user_login
        if (!isEmail(this.user_login) && !isPhoneNumber(this.user_login)) {
          this.login_error = true
          return false
        }

        this.$apollo
          .mutate({
            mutation: require('./gql/loginUser.gql'),
            variables: authDetails
          })
          .then(({ data }) => {
            const userData = data?.Login ?? {}
            this.setUserData(userData)
          })
          .catch((error) => {
            const message = R.pathOr(error.graphQLErrors[0].message, ['graphQLErrors', 0, 'extensions', 'ru'], error)
            this.$noty.show(message)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .reset-pass {
    cursor: pointer;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #00a3ff;
  }
</style>
